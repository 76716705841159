<template>
    <mapa-google
    ref="GoogleMaps"
    :coordenadas="coordenadas"
    :coordenadas-entrada="coordenadas"
    :desactivar-mapa="true"
    icono-central="/img/icons/tendero-point-map.svg"
    :dragable="false"
    height="150px"
    width="100%"
    @actualizar="actualizarCoordenadas"
    />
</template>
<script>
export default {
    props: {
        coordenadas: {
            type: Object,
            required: true,
            default: () => ({ lat: 0, lng: 0 })
        },
        buscar: {
            type: String,
            default: ''
        }
    },
    mounted(){
        this.$refs.GoogleMaps.dibujarMapa()
    },
    methods: {

        accionBuscar(){
            if (this.buscar){
                setTimeout(() => {
                    this.$refs.GoogleMaps.buscarDireccion(this.buscar)
                }, 500);
            }
        },
        actualizarCoordenadas({ lat: latitud, lng: longitud }){
            this.$emit('actualizar', { latitud, longitud })
        },
        setPosition(){
            this.$refs.GoogleMaps.setNewPostition()
        }
    }
}
</script>
